import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Container,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Button,
  InputBase,
  Collapse,
  IconButton,
  List,
} from '@mui/material';
import Link from 'next/link';
import Image from '@app/components/common/unOptimizedNextjsImage';
import locationIcon from '../../../../public/location.svg';
import emailIcon from '../../../../public/email.svg';
import callIcon from '../../../../public/call.svg';
import facebookIcon from '../../../../public/facebook.svg';
import twitterIcon from '../../../../public/twitter.svg';
import instagramIcon from '../../../../public/instagram.svg';
import pinterestIcon from '../../../../public/pinterest.svg';
import youtubeIcon from '../../../../public/youtube.svg';
import coraLogoGray from '../../../../public/cora-logo-gray.svg';
import { styled } from '@mui/material/styles';
import { COLORS } from '@app/utilities/constants';
import AddIcon from '@mui/icons-material/Add';
import { Router, useRouter } from 'next/router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addEmail } from '@app/services/users.service';
import { isMobileWebView } from '@app/utilities/common.utility';
import useWindowSize from '@app/hooks/useWindowSize';

const EmailInput = styled(InputBase)(({ theme }) => ({
  color: 'black',
  flex: 1,
  backgroundColor: '#F5F5F5',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: '400px',
  height: '40px',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiInputBase-input': {
      paddingLeft: '0.5em',
    },
  },
}));
const resources = [
  { title: 'About Us', href: 'about-us' },
  { title: 'Careers', href: '' },
  // { title: 'Contact Us', href: '' },
  {
    title: 'Register Your Brand',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSeC4xcGBWrptEpVPYxmcH0dT-pxspHfvDwKdLFkGTlpye9BCw/viewform',
    alone: true,
  },
  { title: 'Health Blogs', href: 'blogs/flavours-of-india' },
];

const topBrands = [
  { title: 'Muscleblaze', href: 'muscleblaze' },
  { title: 'Myfitness', href: 'myfitness' },
  { title: 'Bigmuscles', href: 'bigmuscles' },
  { title: 'Wonderland Food', href: 'wonderland-foods' },
  { title: 'Oziva', href: 'oziva' },
];

const topCategories = [
  { title: 'Protein Powders', href: 'protein-powders' },
  { title: 'Workout Essentials', href: 'workout-essentials' },
  { title: 'Vitamins', href: 'vitamins' },
  { title: 'Ayurvedic & Herbal Suppliments', href: 'holistic-ayurvedic-products' },
  { title: 'Breakfast Essential', href: 'breakfasts-essentials' },
];

interface IFooter {
  theme?: string;
}

export const Footer = ({ theme }: IFooter) => {
  const { width: windowWidth } = useWindowSize();

  const showSmContent = Boolean(windowWidth && windowWidth < 900);
  const hideForXsContent = Boolean(windowWidth && windowWidth < 600);

  const isDarkTheme = theme === 'dark' ? true : false;

  console.log('theme', theme, isDarkTheme);

  const footerTheme = {
    title: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      color: '#222C30',
      marginBottom: '12px',
      ...(isDarkTheme && { color: COLORS.white }),
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '32px',
      color: '#556166',
      cursor: 'pointer',
      '&:hover': { color: COLORS.green },
      ...(isDarkTheme && { color: COLORS.lightGrey }),
    },
    lineitm: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#222C30',
      ...(isDarkTheme && { color: COLORS.white }),
    },
    signuptxt: {
      color: '#222C30',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      marginBottom: '4px',
      ...(isDarkTheme && { color: COLORS.white }),
    },
    links: {
      color: '#556166',
      fontSize: '12px',
      fontWeight: '400',
      cursor: 'pointer',
      ...(isDarkTheme && { color: COLORS.lightGrey }),
    },
    note: { fontWeight: 700, color: '#222C30', lineHeight: '16px', ...(isDarkTheme && { color: COLORS.white }) },
    subnote: {
      color: '#556166',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      ...(isDarkTheme && { color: COLORS.lightGrey }),
    },
    notecontain: {
      color: '#556166',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      marginTop: '12px',
      ...(isDarkTheme && { color: COLORS.lightGrey }),
    },
    reserved: {
      color: '#556166',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      marginBottom: '12px',
      ...(isDarkTheme && { color: COLORS.lightGrey }),
    },
  };
  const router = useRouter();

  return (
    <Container>
      <Box
        sx={{
          userSelect: 'none',
          paddingTop: '20px',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
          },
        }}
      >
        <Image src={coraLogoGray} width={'83px'} height={'24px'} alt="cora logo gray" />
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '40px' }}>
        {showSmContent && (
          <Grid item xs={12} sm={12}>
            <DropDownList footerTheme={footerTheme} isDarkTheme={isDarkTheme} />
          </Grid>
        )}
        {!hideForXsContent && (
          <Grid
            item
            md={8}
            sx={{
              userSelect: 'none',
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
            }}
          >
            <Grid direction={'row'} container columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="space-between">
              <Grid item>
                <Typography sx={footerTheme.title}>RESOURCES</Typography>
                {resources.map((item, i) => (
                  <React.Fragment key={i}>
                    {item?.href ? (
                      <>
                        {item?.alone ? (
                          <Link href={item?.href} rel="noopener noreferrer">
                            <Typography sx={footerTheme.subtitle}>{item.title}</Typography>
                          </Link>
                        ) : (
                          <Typography sx={footerTheme.subtitle} onClick={() => router.push('/' + item?.href)}>
                            {item.title}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography sx={footerTheme.subtitle}>{item.title}</Typography>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
              <Grid item>
                <Typography sx={footerTheme.title}>TOP BRANDS</Typography>
                {topBrands.map((item, i) => (
                  <React.Fragment key={i}>
                    {item?.href ? (
                      <Typography sx={footerTheme.subtitle} onClick={() => router.push('/collections/' + item?.href)}>
                        {item.title}
                      </Typography>
                    ) : (
                      <Typography sx={footerTheme.subtitle}>{item.title}</Typography>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
              <Grid item>
                <Typography sx={footerTheme.title}>TOP CATEGORIES</Typography>
                {topCategories.map((item, i) => (
                  <React.Fragment key={i}>
                    {item?.href ? (
                      <Typography sx={footerTheme.subtitle} onClick={() => router.push('/collections/' + item?.href)}>
                        {item.title}
                      </Typography>
                    ) : (
                      <Typography sx={footerTheme.subtitle}>{item.title}</Typography>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <CompanyAddress footerTheme={footerTheme} />
          <NewsLetterSignup footerTheme={footerTheme} />
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: isDarkTheme ? '#D4D4D4' : '#E9EAEA', marginTop: '40px', marginBottom: '32px' }} />
      <Grid
        container
        sx={{
          paddingBottom: '32px',
          userSelect: 'none',
          flexDirection: {
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'row',
          },
        }}
        justifyContent="space-between"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography sx={footerTheme.reserved}>{'© 2021 Cora Health. All Rights Reserved.'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{}}>
              <Grid item sx={{}}>
                <Typography sx={footerTheme.links} onClick={() => router.push('/shipping-delivery')}>
                  {'Shipping & Delivery'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#556166', fontSize: '12px', fontWeight: '400' }}>&nbsp; :: &nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography sx={footerTheme.links} onClick={() => router.push('/privacy-policy')}>
                  {'Privacy Policy'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#556166', fontSize: '12px', fontWeight: '400' }}>&nbsp; :: &nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography sx={footerTheme.links} onClick={() => router.push('/terms-conditions')}>
                  {'Term of Use'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#556166', fontSize: '12px', fontWeight: '400' }}>
                  {' '}
                  &nbsp; :: &nbsp;{' '}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={footerTheme.links} onClick={() => router.push('/refund-policy')}>
                  {'Cancellation & Refund Policy'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={footerTheme.notecontain}>
              <Box component="span" sx={footerTheme.note}>
                {'Please note: '}
              </Box>
              All product information are provided by the brands and are not a substitute for medical advice.
            </Typography>
            <Typography sx={footerTheme.subnote}>
              Please seek advice from a medical professional for any serious health ailments.
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <SocialMediaLogos />
        </Grid>
      </Grid>
    </Container>
  );
};

export function CompanyAddress({ footerTheme }) {
  const router = useRouter();
  return (
    <React.Fragment>
      <ListItem alignItems="flex-start">
        <ListItemIcon sx={{ minWidth: '24px', marginRight: '12px' }}>
          <Image src={locationIcon} width={'24px'} height={'24px'} alt="location img" />
        </ListItemIcon>
        <ListItemText
          primary={'677, 1st Floor, 27th Main, 13th Cross, HSR Layout, Phase 1, Bangalore -560102'}
          sx={footerTheme.lineitm}
        />
      </ListItem>
      <ListItem
        onClick={() => {
          let emailOpen = { type: 'email', email: 'support@corahealthcare.in' };
          if (window?.ReactNativeWebView && isMobileWebView()) {
            window?.ReactNativeWebView?.postMessage(JSON.stringify(emailOpen));
          } else {
            router.push('mailto:support@corahealthcare.in');
          }
        }}
      >
        <ListItemIcon sx={{ minWidth: '24px', marginRight: '12px' }}>
          <Image src={emailIcon} width={'24px'} height={'24px'} alt="email img" />
        </ListItemIcon>
        <ListItemText primary={'support@corahealthcare.in'} sx={footerTheme.lineitm} />
      </ListItem>
      <ListItem
        onClick={() => {
          let phoneOpen = { type: 'phone', number: '07353008799' };
          if (window?.ReactNativeWebView && isMobileWebView()) {
            window?.ReactNativeWebView?.postMessage(JSON.stringify(phoneOpen));
          } else {
            router.push('tel:07353008799');
          }
        }}
      >
        <ListItemIcon sx={{ minWidth: '24px', marginRight: '12px' }}>
          <Image src={callIcon} width={'24px'} height={'24px'} alt="call img" />
        </ListItemIcon>
        <ListItemText primary={'+91 7353008799'} sx={footerTheme.lineitm} />
      </ListItem>
    </React.Fragment>
  );
}

export function NewsLetterSignup({ footerTheme }) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmail('');
  };

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const subscribe = () => {
    if (validateEmail(email)) {
      console.log('name', email);
      addEmail(email);
      setOpen(true);
    } else {
      setEmailError(true);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Thankyou for subscribing!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will get daily health and wellness dose in your inbox, Earn 100 Cora Cash. No Spam, we promise.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          userSelect: 'none',
          marginBottom: '16px',
          marginTop: {
            xs: '26px',
            sm: '26px',
            md: '42px',
          },
          paddingLeft: '16px',
        }}
      >
        <Typography sx={footerTheme.signuptxt}>Signup for the Newsletter</Typography>
        <Typography
          sx={{
            color: '#556166',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '16px',
          }}
        >
          Get your daily health and wellness dose in your inbox,
        </Typography>
        <Typography
          sx={{
            color: '#556166',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '16px',
          }}
        >
          Earn 100 Cora Cash. No Spam, we promise.
        </Typography>
      </Box>
      <Grid container sx={{ marginTop: '16px', paddingLeft: '16px' }}>
        <EmailInput
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(false);
          }}
        />
        <Button
          variant="contained"
          sx={{
            marginLeft: '17px',
            fontSize: '14px',
            width: '99px',
            height: '40px',
            lineHeight: '20px',
            fontWeight: 600,
            background: '#11B69C',
            textTransform: 'none',
            borderRadius: '8px',
            '&:hover': { background: '#11B69C' },
          }}
          fullWidth
          onClick={() => subscribe()}
        >
          Subscribe
        </Button>
        <Grid item xs={12}>
          {emailError && <Typography sx={{ fontSize: '10px', color: 'red' }}>Email not valid</Typography>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export function SocialMediaLogos() {
  return (
    <React.Fragment>
      <ListItem
        sx={{
          userSelect: 'none',
          justifyContent: 'left',
          marginBottom: {
            xs: '24px',
          },
        }}
      >
        <Box component="a" href="https://facebook.com/corahealth.in" sx={{ paddingRight: '14px', cursor: 'pointer' }}>
          <Image src={facebookIcon} width={'40'} height={'40'} alt="facebook img" />
        </Box>
        <Box component="a" href="https://twitter.com/health_cora" sx={{ paddingRight: '14px', cursor: 'pointer' }}>
          <Image src={twitterIcon} width={'40'} height={'40'} alt="twitter img" />
        </Box>
        <Box
          component="a"
          href="https://www.instagram.com/corahealth.in/"
          sx={{ paddingRight: '14px', cursor: 'pointer' }}
        >
          <Image src={instagramIcon} width={'40'} height={'40'} alt="instagram img" />
        </Box>
        <Box
          component="a"
          href="https://pinterest.com/corahealthindia"
          sx={{ paddingRight: '14px', cursor: 'pointer' }}
        >
          <Image src={pinterestIcon} width={'40'} height={'40'} alt="pinterst img" />
        </Box>
        <Box
          component="a"
          href="https://www.youtube.com/channel/UCZ718ih42AwfAzulC01P5fQ"
          sx={{ paddingRight: '14px', cursor: 'pointer' }}
        >
          <Image src={youtubeIcon} width={'40'} height={'40'} alt="youtube img" />
        </Box>
      </ListItem>
    </React.Fragment>
  );
}

interface IObjectKeys {
  [key: number]: boolean;
}

export function DropDownList({ footerTheme, isDarkTheme }) {
  const [getCollapse, setCollapse] = React.useState<IObjectKeys>({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const handleCollapse = (target: any) => {
    setCollapse({ [target]: !getCollapse[target] });
  };
  return (
    <React.Fragment>
      <List
        sx={{
          userSelect: 'none',
          display: {
            md: 'none',
            lg: 'none',
          },
        }}
      >
        <Divider sx={{ backgroundColor: isDarkTheme ? '#D4D4D4' : '#000000' }} />
        <ListItem
          alignItems="flex-start"
          onClick={() => handleCollapse(0)}
          secondaryAction={
            <IconButton edge="end">
              <AddIcon style={{ color: isDarkTheme ? '#D4D4D4' : '#000000' }} />
            </IconButton>
          }
        >
          <Typography sx={footerTheme.title}>RESOURCES</Typography>
        </ListItem>
        <Collapse in={getCollapse[0]} timeout="auto" unmountOnExit>
          {resources.map((item, i) => (
            <ListItem key={i} sx={{ pt: 0, pb: 0 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  color: '#556166',
                  '&:hover': { color: COLORS.green },
                }}
              >
                {item.title}
              </Typography>
            </ListItem>
          ))}
        </Collapse>
        <Divider sx={{ backgroundColor: isDarkTheme ? '#D4D4D4' : '#000000' }} />
        <ListItem
          alignItems="flex-start"
          onClick={() => handleCollapse(1)}
          secondaryAction={
            <IconButton edge="end">
              <AddIcon style={{ color: isDarkTheme ? '#D4D4D4' : '#000000' }} />
            </IconButton>
          }
        >
          <Typography sx={footerTheme.title}>TOP BRANDS</Typography>
        </ListItem>
        <Collapse in={getCollapse[1]} timeout="auto" unmountOnExit>
          {topBrands.map((item, i) => (
            <ListItem key={i} sx={{ pt: 0, pb: 0 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '32px', color: '#556166' }}>
                {item.title}
              </Typography>
            </ListItem>
          ))}
        </Collapse>
        <Divider sx={{ backgroundColor: isDarkTheme ? '#D4D4D4' : '#000000' }} />
        <ListItem
          alignItems="flex-start"
          onClick={() => handleCollapse(2)}
          secondaryAction={
            <IconButton edge="end">
              <AddIcon style={{ color: isDarkTheme ? '#D4D4D4' : '#000000' }} />
            </IconButton>
          }
        >
          <Typography sx={footerTheme.title}>TOP CATEGORIES</Typography>
        </ListItem>

        <Collapse in={getCollapse[2]} timeout="auto" unmountOnExit>
          {topCategories.map((item, i) => (
            <ListItem key={i} sx={{ pt: 0, pb: 0 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '32px', color: '#556166' }}>
                {item.title}
              </Typography>
            </ListItem>
          ))}
        </Collapse>
      </List>
    </React.Fragment>
  );
}
